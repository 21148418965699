function closeModal() {

    jQuery('html').removeClass('modal-open');
    jQuery('.modal-backdrop').remove();
    jQuery('.modal').removeClass('show').delay(300).hide('fast');

}

function openModal() {

    jQuery('[href="#modal"]').click(function(ev) {
        ev.preventDefault();

        jQuery('html').addClass('modal-open');
        jQuery('body').append('<div class="modal-backdrop"></div>');
        jQuery('.modal-backdrop').addClass('show');
        jQuery('.modal').show(300, function() {
            jQuery(this).addClass('show');
        });
    });

    jQuery('.modal').on('click', '.close', function(ev) {
        ev.preventDefault();
        closeModal();
    });
}
