function switchForms() {

    jQuery('.request__tabs').on('click', 'a', function(ev) {
        ev.preventDefault();

        jQuery('.request__tabs a').removeClass('active');
        jQuery(this).addClass('active');
        jQuery('.request__form-holder .form').removeClass('active');
        jQuery('.request__form-holder').find(jQuery(jQuery(this).attr('href'))).addClass('active');

        jQuery('.error').removeClass('error');
        jQuery('.error-list').remove();
    });

}

function showFormOnHash() {

    var form_hashes = ['#apply', '#more-information', '#tell-a-friend'];

    jQuery(document).on('click', 'a[href^="#"]', function(ev) {
        ev.preventDefault();
        if (jQuery.inArray(jQuery(this).attr('href'), form_hashes) > 0) {
            jQuery('.request__tabs a').removeClass('active');
            jQuery('.request__tabs').find(jQuery('a[href="'+ jQuery(this).attr('href') +'"]')).addClass('active');
            jQuery('.request__form-holder .form').removeClass('active');
            jQuery('.request__form-holder').find(jQuery(jQuery(this).attr('href'))).addClass('active');
            jQuery('.error').removeClass('error');
            jQuery('.error-list').remove();

            jQuery('html, body').stop().animate({
                scrollTop: jQuery('#section-6').offset().top - (jQuery('.site-header').outerHeight())
            }, {
                duration: 600,
                complete: function() {
                    if (MQ !== 'desktop') {
                        jQuery('html').removeClass('menu-open');
                    }
                }
            });
        }
    });

}
