function showQuickContact() {

    jQuery('.quick-contact').on('click', '.close', function() {
        jQuery('.quick-contact').removeClass('show').addClass('clicked');
    });

    jQuery(window).scroll(function() {
        var elem = jQuery('.request__form-wrapper'),
            docViewTop = jQuery(window).scrollTop(),
            docViewBottom = (docViewTop + jQuery(window).height()) - 200,
            elemTop = jQuery(elem).offset().top;

        if (!jQuery('.quick-contact').hasClass('clicked')) {
            if ((elemTop <= docViewBottom) && (elemTop >= docViewTop)) {
                jQuery('.quick-contact').addClass('show');
            }
        }
    });

}
