/* ========================================================================
 COOKIE ALERT
 * ======================================================================== */
function initCookieAlert() {
    jQuery('.cookie__accept').click(function (event) {
        event.preventDefault();
        jQuery('.cookie-alert').fadeOut('300');
        Cookies.set('accepted', 'true', {expires: 30});
    });

    if (Cookies.get('accepted') === 'true') {
        jQuery('.cookie-alert').fadeOut('300');
    } else {
        jQuery('.cookie-alert').fadeIn('300');
    }
}
