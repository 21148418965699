var didScroll,
    lastScrollTop = 0,
    delta = 5,
    siteHeader = jQuery('.site-header'),
    navbarHeight = siteHeader.outerHeight();

function hasScrolled() {

    var st = jQuery(this).scrollTop();

    if (Math.abs(lastScrollTop - st) <= delta) {
        return;
    }

    if (st > lastScrollTop && st > navbarHeight) {
        siteHeader.addClass('show-button');
    } else {
        if (st + jQuery(window).height() < jQuery(document).height()) {
            siteHeader.removeClass('show-button');
        }
    }

    lastScrollTop = st;

}

function scrollingHeader() {

    jQuery(window).scroll(function(event) {
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

}

function openMenu() {

    jQuery('.hamburger').on('click', function(ev) {
        ev.preventDefault();

        jQuery('html').toggleClass('menu-open');
    });

}
