/* ========================================================
 Check if mobile / desktop
======================================================== */

function deviceType() {
    //detect if desktop/mobile
    return window.getComputedStyle(document.querySelector('body'), '::before').getPropertyValue('content').replace(/"/g, "").replace(/'/g, "");
}

var MQ = deviceType();

/* ----------------------------------------------------------------
 * INIT ALL THE THINGS!
 * ---------------------------------------------------------------- */
(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                if ($('body').hasClass('home')) {
                    scrollNavigation();
                }
                getHashUrl();
                scrollButton();
                initCookieAlert();
                openModal();
                showFormOnHash();

                if (MQ !== 'desktop') {
                    openMenu();
                }

                if (MQ === 'desktop' && $('.quick-contact').length > 0) {
                    showQuickContact();
                }

                if (MQ === 'mobile') {
                    scrollingHeader();
                }

                if ($('.faq-holder').length > 0) {
                    initFaq();
                }

                if ($('.calculator__slider').length > 0) {
                    discountSlider();
                }

                if ($('.request__form-holder').length > 0) {
                    switchForms();
                    selectStrips();

                    if ($('#request-apply').length > 0) {
                        sendApply();
                    }
                    if ($('#request-more-information').length > 0) {
                        sendMoreInformation();
                    }
                    if ($('#request-tell-a-friend').length > 0) {
                        sendFriendRequest();
                    }
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
