function switchValues(val) {
    switch (val) {
        case 1:
            strips = "1";
            strip_count = "20";
            price_old = "13.900";
            price_new = "10.580";
            discount = "24";
            break;
        case 2:
            strips = "2";
            strip_count = "25";
            price_old = "17.375";
            price_new = "12.825";
            discount = "26";
            break;
        case 3:
            strips = "3";
            strip_count = "30";
            price_old = "20.850";
            price_new = "15.120";
            discount = "27";
            break;
        case 4:
            strips = "4";
            strip_count = "40";
            price_old = "27.800";
            price_new = "19.840";
            discount = "29";
            break;
        case 5:
            strips = "5";
            strip_count = "50";
            price_old = "34.750";
            price_new = "24.300";
            discount = "30";
            break;
        case 6:
            strips = "6";
            strip_count = "75";
            price_old = "52.125";
            price_new = "34.200";
            discount = "34";
            break;
        case 7:
            strips = "7";
            strip_count = "100";
            price_old = "69.500";
            price_new = "44.000";
            discount = "37";
            break;
        case 8:
            strips = "8";
            strip_count = "300";
            price_old = "208.500";
            price_new = "126.000";
            discount = "40";
            break;
    }

    jQuery('.price-value-discount').text(discount);
    jQuery('.price-value-old').text(price_old);
    jQuery('.price-value-new').text(price_new);
    jQuery('[name="strips"]').val(strips);
    document.getElementById('strips_count').setAttribute('value', strip_count);
}

function discountSlider() {
    var calculator = jQuery('.calculator__slider'),
        sizes = ["20", "25", "30", "40", "50", "75", "100", "300"],
        selected_val;

    calculator.slider({
        range: "min",
        min: 1,
        max: 8,
        step: 1,
        value: 4,
        create: function () {
            selected_val = calculator.slider("value");
            switchValues(selected_val);
        },
        change: function (event, ui) {
            selected_val = ui.value;
            switchValues(selected_val);
        }
    }).slider("pips", {
        rest: "label",
        labels: sizes,
        suffix: " strippen"
    });
}

function selectStrips() {
    jQuery(document).on('change', '[name="strips"]', function () {
        jQuery('.calculator__slider').slider('value', jQuery(this).val());
    });
}
