var form,
    url,
    validator,
    form_data_append,
    form_data = {
        action : 'send_email'
    };

function sendEmailAjaxRequest(data, url) {
    jQuery.ajax({
        url: custom_ajax.ajax_url,
        type: 'POST',
        data: form_data,
        success: function(response) {
            if (url !== '') {
                window.location.href = url;
            } else {
                setTimeout(function() {
                    jQuery('.request__form input').val('');
                    jQuery('.error-list').slideUp(300, function() {
                        jQuery(this).remove();
                    });
                }, 1000);
            }
        },
        error: function( e ) {
            console.error(e);
        }
    });
}

function validateFields(form) {
    var error_list = '',
        error = false;

    jQuery('.error').removeClass('error');
    jQuery('.error-list').remove();

    var email = form.find('input[name="email"]').val().replace(/ /g, ''),
        regexMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (jQuery('#tell-a-friend').hasClass('active')) {
        var name = form.find('input[name="firstname"]').val().replace(/ /g, '');
        if (( name === '' ) || ( name === 'Voornaam' )) {
            form.find('.your-name').addClass('error');
            error_list = '<li>Een of meer velden bevatten een fout. Graag corrigeren en opnieuw proberen.</li>';
            error = true;
        }

        if (!regexMail.test(email)) {
            form.find('.email').addClass('error');
            if (error_list === '') {
                error_list = '<li>Het ingevoerde e-mailadres is onjuist.</li>';
            }
            error = true;
        }

        var c_email = form.find('input[name="colleague_email"]').val().replace(/ /g, '');
        if (!regexMail.test(c_email)) {
            form.find('#colleague_email').addClass('error');
            if (error_list === '') {
                error_list = '<li>Het ingevoerde e-mailadres is onjuist.</li>';
            }
            error = true;
        }
    } else {
        var firstname = form.find('input[name="voornaam"]').val().replace(/ /g, '');
        if (( firstname === '' ) || ( firstname === 'Voornaam' )) {
            form.find('.first-name').addClass('error');
            error_list = '<li>Een of meer velden bevatten een fout. Graag corrigeren en opnieuw proberen.</li>';
            error = true;
        }

        var lastname = form.find('input[name="achternaam"]').val().replace(/ /g, '');
        if (( lastname === '' ) || ( lastname === 'Achternaam' )) {
            form.find('.last-name').addClass('error');
            error_list = '<li>Een of meer velden bevatten een fout. Graag corrigeren en opnieuw proberen.</li>';
            error = true;
        }


        var company = form.find('input[name="company"]').val().replace(/ /g, '');
        if (( company === '' ) || ( company === 'Bedrijfsnaam' )) {
            form.find('.company').addClass('error');
            error_list = '<li>Een of meer velden bevatten een fout. Graag corrigeren en opnieuw proberen.</li>';
            error = true;
        }

        if (!regexMail.test(email)) {
            form.find('.email').addClass('error');
            if (error_list === '') {
                error_list = '<li>Het ingevoerde e-mailadres is onjuist.</li>';
            }
            error = true;
        }
    }

    if (error_list !== '') {
        error_list = '<ul class="error-list not-valid">' + error_list + '</ul>';
    } else {
        error_list = '<ul class="error-list is-valid"><li>Bedankt voor je bericht. Het is verzonden.</li></ul>';
    }

    return error_list;

}

function sendApply() {

    jQuery('#request-apply').on('submit', function() {
        form = jQuery('#request-apply');
        validator = validateFields(form);

        if (validator === '<ul class="error-list is-valid"><li>Bedankt voor je bericht. Het is verzonden.</li></ul>') {
            var url = form.attr('action');

            form_data_append = {
                firstname    : form.find('[name="voornaam"]').val(),
                lastname     : form.find('[name="achternaam"]').val(),
                company      : form.find('[name="company"]').val(),
                email        : form.find('[name="email"]').val(),
                phonenumber  : form.find('[name="phonenumber"]').val(),
                strips       : form.find('[name="strips_count"]').val(),
                subject      : 'High Five! Je hebt ervoor gekozen om samen te groeien.',
                form         : 'apply'
            };

            jQuery.extend(form_data, form_data_append);
            sendEmailAjaxRequest(form_data, url);
        }

        jQuery(validator).insertAfter(jQuery('.form-footer')).slideDown(300);

        return false;
    });

}

function sendMoreInformation() {

    jQuery('#request-more-information').on('submit', function() {
        form = jQuery('#request-more-information');
        validator = validateFields(form);

        if (validator === '<ul class="error-list is-valid"><li>Bedankt voor je bericht. Het is verzonden.</li></ul>') {
            var url = form.attr('action');

            form_data_append = {
                firstname    : form.find('[name="voornaam"]').val(),
                lastname     : form.find('[name="achternaam"]').val(),
                company      : form.find('[name="company"]').val(),
                email        : form.find('[name="email"]').val(),
                phonenumber  : form.find('[name="phonenumber"]').val(),
                subject      : 'Bedankt voor je interesse in de Vijfhart-strippenkaart. ',
                form         : 'more-information'
            };

            jQuery.extend(form_data, form_data_append);
            sendEmailAjaxRequest(form_data, url);
        }

        jQuery(validator).insertAfter(jQuery('.form-footer')).slideDown(300);

        return false;
    });

}

function sendFriendRequest() {

    jQuery('#request-tell-a-friend').on( 'submit', function() {
        form = jQuery('#request-tell-a-friend');
        validator = validateFields(form);

        if (validator === '<ul class="error-list is-valid"><li>Bedankt voor je bericht. Het is verzonden.</li></ul>') {
            var url = form.attr('action');

            form_data_append = {
                colleague_name: form.find('[name="colleague-name"]').val(),
                colleague_email: form.find('[name="colleague_email"]').val(),
                firstname: form.find('[name="firstname"]').val(),
                email: form.find('[name="email"]').val(),
                subject: 'Hi, tip van jouw collega: de Vijfhart-strippenkaart.',
                form: 'tell-a-friend'
            };

            jQuery.extend(form_data, form_data_append);
            sendEmailAjaxRequest(form_data, url);
        }

        jQuery(validator).insertAfter(jQuery('.form-footer')).slideDown(300);

        return false;
    });

}
