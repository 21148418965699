function getHashUrl() {

    if (location.hash) {
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);

        var section = window.location.href.replace(/^.*?(#|$)/,'');

        jQuery('html, body').stop().animate({
            scrollTop: jQuery('#' + section).offset().top - (jQuery('.site-header').outerHeight())
        }, {
            duration: 600,
            complete: function() {
                if (MQ !== 'desktop') {
                    jQuery('html').removeClass('menu-open');
                }
            }
        });
    }

}

function scrollNavigation() {

    jQuery('.nav-main-item a').click(function() {
        var section = jQuery(this).attr('href').replace(/^.*?(#|$)/,'');

        jQuery('html, body').stop().animate({
            scrollTop: jQuery('#' + section).offset().top - (jQuery('.site-header').outerHeight())
        }, {
            duration: 600,
            complete: function() {
                if (MQ !== 'desktop') {
                    jQuery('html').removeClass('menu-open');
                }
            }
        });

        return false;
    });

}

function scrollButton() {

    jQuery(document).on('click', '.button--scroll', function() {
        var section = jQuery(this).attr('href').replace(/^.*?(#|$)/,'');

        jQuery('html, body').stop().animate({
            scrollTop: jQuery('#' + section).offset().top - (jQuery('.site-header').outerHeight())
        }, {
            duration: 600
        });

        return false;
    });

}
