function initFaq() {

    jQuery('.faq__item__question').on('click', function() {
        var expand = jQuery(this).next('.faq__item__answer');

        jQuery('.faq__item__question').not(jQuery(this)).removeClass('active');
        jQuery('.faq__item__answer').not(expand).stop(true, false).slideUp(400);
        jQuery(this).toggleClass('active');
        expand.stop(true, false).slideToggle(400);

        return false;
    });

}
